import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#EBE7DD',
      dark: '#D6D2C9',
      light: '#F0EDE5',
    },
    secondary: {
      main: '#101010',
      dark: '#000000',
      light: '#282c34',
    },
    accent: {
      main: '#FF841A',
      dark: '#E67616',
      light: '#FF9933',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#B3B3B3',
    },
    background: {
      default: '#101010',
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        color: '#EBE7DD',
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#FF841A',
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#FF9933',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#EBE7DD',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#282c34',
          backgroundImage: 'none',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#282c34',
          backgroundImage: 'none',
        },
      },
    },
  },
}); 
