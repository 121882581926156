import { Box, Paper, Avatar, Typography, IconButton, Button, Alert, Snackbar, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Select, MenuItem, FormControl, InputLabel, Tooltip } from '@mui/material';
import { Menu as MenuIcon, InfoOutlined } from '@mui/icons-material';
import { useState, useEffect, forwardRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Outlet } from 'react-router-dom';

import Sidebar from '../Sidebar';
import { useAuth } from '../../hooks/useAuth';
import { requestAssistance } from '../../api/travel';

const CustomPhoneInput = forwardRef((props, ref) => (
  <TextField
    {...props}
    inputRef={ref}
    fullWidth
    margin="normal"
    variant="outlined"
  />
));

CustomPhoneInput.displayName = 'CustomPhoneInput';

const Layout = () => {
  const { t } = useTranslation();
  const { currentUser, isSetupComplete, acceptedTerms } = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(currentUser?.profilePhotoUrl);
  const [modalOpen, setModalOpen] = useState(false);
  const isSetupAndTermsComplete = !!isSetupComplete && !!acceptedTerms;
  const [formData, setFormData] = useState({});

  const resetFormData = useCallback(() => {
    setFormData({
      fullName: currentUser?.name,
      contactPhone: currentUser?.phone,
      travelDetails: '',
      destinations: '',
      departureDate: '',
      returnDate: '',
      purpose: '',
      otherPurpose: '',
    });
  }, [currentUser]);

  useEffect(() => {
    if (currentUser?.profilePhotoUrl) {
      setAvatarUrl(`${currentUser.profilePhotoUrl}?t=${new Date().getTime()}`);
      resetFormData();
    }
  }, [currentUser, resetFormData]);

  const toggleDrawer = (open) => {  
    setDrawerOpen(open);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    resetFormData();
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    if(name === 'purpose' && value !== 'Other') {
      setFormData((prevData) => ({ ...prevData, otherPurpose: '' }));
    }

    if(name === 'returnDate') {
      if(formData.departureDate && value < formData.departureDate) {
        setFormData((prevData) => ({ ...prevData, departureDate: value }));
      }
    }

    if(name === 'departureDate') {
      if(formData.returnDate && value > formData.returnDate) {
        setFormData((prevData) => ({ ...prevData, returnDate: value }));
      }
    }
  };

  const handleConfirmAssistance = async () => {
    setIsLoading(true);
    try {
      await requestAssistance(formData);
      setShowSuccess(true);
      handleCloseModal();
      resetFormData();
    } catch (error) {
      console.error('Error requesting assistance:', error);
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };


  const isFormValid = () => {
    const { fullName, contactPhone, destinations, purpose, otherPurpose, departureDate, returnDate } = formData;
    return fullName && 
    contactPhone && 
    destinations && 
    (purpose && purpose !== 'select' && (purpose !== 'Other' || otherPurpose)) && 
    departureDate && 
    returnDate;
  };


  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      height: '100vh',
      bgcolor: 'background.default',
      color: 'text.primary'
    }}>
      {!!currentUser && (
        <>
          <Paper 
            elevation={2}
            sx={{
              p: 2,
              display: 'flex',
              alignItems: 'center',
              gap: 3,
              zIndex: 1100,
            }}
          >
            <IconButton onClick={() => toggleDrawer(true)} sx={{ ml: 1 }}>
              <MenuIcon />
            </IconButton>
            
            <Avatar
              src={avatarUrl}
              sx={{ width: 64, height: 64 }}
            />
            <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box>
                <Typography variant="h6" component="h1" fontWeight="bold">
                  {currentUser.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ maxWidth: '600px' }}>
                  {currentUser.description || t('noBiography')}
                </Typography>
              </Box>
              {isSetupAndTermsComplete && (
                <Button 
                  onClick={handleOpenModal} 
                  disabled={isLoading}
                  sx={{ 
                    bgcolor: 'accent.main', 
                    color: 'white',
                    '&.Mui-disabled': {
                      bgcolor: 'action.disabledBackground',
                      color: 'action.disabled'
                    }
                  }}
                >
                  <Typography variant="body2">
                    {t('requestTravelAssistance')}
                  </Typography>
                </Button>
              )}
            </Box>
          </Paper>
          
          {isSetupAndTermsComplete && <Sidebar open={drawerOpen} onClose={() => toggleDrawer(false)} />}
        </>
      )}
      
      <Box 
        component="main" 
        sx={{ 
          flexGrow: 1, 
          p: 3, 
          overflow: 'auto',
          bgcolor: 'background.default'
        }}
      >
        <Outlet />
      </Box>
      <Snackbar
        open={showSuccess}
        autoHideDuration={2000}
        onClose={() => setShowSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setShowSuccess(false)} 
          severity="success" 
          sx={{ width: '100%' }}
        >
          {t('travelAssistanceRequestSent')}
        </Alert>
      </Snackbar>

      <Snackbar
        open={showError}
        autoHideDuration={2000}
        onClose={() => setShowError(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setShowError(false)} 
          severity="error" 
          sx={{ width: '100%' }}
        >
          {t('failedToSendTravelAssistanceRequest')}
        </Alert>
      </Snackbar>

      <Dialog open={modalOpen} onClose={handleCloseModal} PaperProps={{ sx: { borderRadius: 4 } }}>
        <DialogTitle>{t('requestAssistance')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="fullName"
            label={t('fullName')}
            type="text"
            fullWidth
            variant="standard"
            value={formData.fullName}
            onChange={handleFormChange}
            required
          />
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', flexGrow: 1 }}>
            <PhoneInput
              placeholder={t('phone')}
              name="contactPhone"
              value={formData.contactPhone}
              onChange={(value) => setFormData((prevData) => ({ ...prevData, contactPhone: value }))}
              defaultCountry="US"
              international
              inputComponent={CustomPhoneInput}
              required
              style={{ flexGrow: 1 }}
            />
            {!formData.contactPhone && (
              <Tooltip title={t('youCanFillPhoneInProfile')}>
                <InfoOutlined sx={{ ml: 1, color: 'text.secondary' }} />
              </Tooltip>
            )}
          </Box>
          <TextField
            margin="dense"
            name="destinations"
            label={t('destinations')}
            type="text"
            fullWidth
            variant="standard"
            value={formData.destinations}
            onChange={handleFormChange}
            required
          />
          <FormControl 
            margin="dense" 
            fullWidth 
            variant="standard" 
            sx={{ mt: 2 }} 
          >
            <InputLabel id="purpose-label">{t('tripPurpose')}</InputLabel>
            <Select
              labelId="purpose-label"
              name="purpose"
              value={formData.purpose || 'select'}
              onChange={handleFormChange}
              displayEmpty
              required
            >
              <MenuItem value="select">{t('selectTripPurpose')}</MenuItem>
              <MenuItem value="Tourism">{t('tourism')}</MenuItem>
              <MenuItem value="Business">{t('business')}</MenuItem>
              <MenuItem value="Other">{t('other')}</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            name="otherPurpose"
            label={t('specifyOtherPurpose')}
            type="text"
            fullWidth
            variant="standard"
            value={formData.otherPurpose}
            disabled={formData.purpose !== 'Other'}
            onChange={handleFormChange}
          />
          <TextField
            margin="dense"
            name="departureDate"
            label={t('departureDate')}
            type="date"
            variant="standard"
            value={formData.departureDate}
            onChange={handleFormChange}
            slotProps={{
              inputLabel: {
                shrink: true,
              },
              htmlInput: {
                min: new Date().toISOString().split('T')[0],
              }
            }}
            sx={{ mr: 5 }}
            required
          />
          <TextField
            margin="dense"
            name="returnDate"
            label={t('returnDate')}
            type="date"
            variant="standard"
            value={formData.returnDate}
            onChange={handleFormChange}
            slotProps={{
              inputLabel: {
                shrink: true,
              },
              htmlInput: {
                min: formData.departureDate ? new Date(formData.departureDate).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]
              }
            }}
            required
          />
          <TextField
            margin="normal"
            name="travelDetails"
            label={t('travelDetails')}
            type="text"
            fullWidth
            multiline
            rows={4}
            value={formData.travelDetails}
            onChange={handleFormChange}
          />
          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            {t('travelAssistanceRequestFormNote')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}
            sx={{
              mb:2,
            }}
          >
            {t('cancel')}
          </Button>
          <Button onClick={handleConfirmAssistance} disabled={!isFormValid() || isLoading}
            sx={{
              mb:2,
              mr:2,
              bgcolor: isLoading || !isFormValid() ? 'secondary.main' : 'accent.main',
            }}
          >
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Layout;
