import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Typography, CircularProgress } from '@mui/material';
import { LineChart } from '@mui/x-charts';

const getProgressColor = (progress) => {
  if (progress >= 80) return '#2e7d32';
  if (progress >= 50) return '#ed6c02';
  return '#d32f2f';
};

export const NetWorthChart = memo(({ netWorthData, targetGrowthPercentage }) => {
  const { t } = useTranslation();
  const currentValue = netWorthData[netWorthData.length - 1].value;
  const initialValue = netWorthData[0].value;
  const actualGrowth = ((currentValue - initialValue) / initialValue) * 100;
  const progress = (actualGrowth / targetGrowthPercentage) * 100;
  const color = getProgressColor(progress);

  return (
    <Box sx={{ 
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      gap: 2,
      py: 2,
      ml: 5,
      mr: 5
    }}>
      <Box sx={{ 
        position: 'absolute',
        top: 0,
        right: 20,
      }}>
        <CircularProgress 
          variant="determinate" 
          value={Math.min(progress, 100)}
          size={80}
          sx={{ color }}
        />
        <Box sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <Typography variant="h6" color="text.primary">
            {Math.min(progress.toFixed(0), 100)}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ width: '100%', height: 250 }}>
        <LineChart
          dataset={netWorthData}
          xAxis={[{ 
            dataKey: 'date',
            scaleType: 'time',
            valueFormatter: (date) => date.toLocaleDateString(),
          }]}
          yAxis={[{
            valueFormatter: (value) => `$${Math.round(value).toLocaleString()}`,
            tickNumber: 5,
            min: 0
          }]}
          series={[{
            dataKey: 'value',
            area: true,
            color,
            showMark: false,
          }]}
          height={250}
          margin={{ top: 35, bottom: 35, left: 70, right: 25 }}
        />
      </Box>

      <Box sx={{ textAlign: 'center', mt: 'auto' }}>
        <Typography variant="h5" fontWeight="normal" mb={1} mt={-2}>
          {t('totalNetWorth')}: ${currentValue.toLocaleString()}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {t('targetGrowthPercentage')}: {targetGrowthPercentage}%
        </Typography>
      </Box>
    </Box>
  );
});

NetWorthChart.propTypes = {
  netWorthData: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.instanceOf(Date).isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  targetGrowthPercentage: PropTypes.number.isRequired,
};

NetWorthChart.displayName = 'NetWorthChart'; 