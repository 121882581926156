import { Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Auth/Login';
import Finance from './pages/Finance';
import ResetPassword from './pages/Auth/PasswordReset';
import ProtectedRoute from './components/ProtectedRoute';
import UnprotectedRoute from './components/UnprotectedRoute';
import ProfileSetup from './pages/Auth/ProfileSetup';
import EditProfile from './pages/EditProfile';
import Layout from './components/Layout';
import Settings from './pages/Settings';
import { GoogleCalendarCallback } from './pages/Integrations/GoogleCalendar';
import { XCallback } from './pages/Integrations/X';
import Legal from './pages/Legal';
import SetupCompleteRoute from './components/SetupCompleteRoute';

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<UnprotectedRoute />}>
        <Route element={<Layout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/password-reset" element={<ResetPassword />} />
        </Route>
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route element={<Layout />}>
          <Route path="/profile-setup" element={<ProfileSetup />} />
          <Route path="/legal" element={<Legal />} />
          <Route element={<SetupCompleteRoute />}>
            <Route path="/home" element={<Home />} />
            <Route path="/edit-profile" element={<EditProfile />} />
            <Route path="/finance" element={<Finance />} />
            <Route path="/settings" element={<Settings />} />
            {/* <Route path="/calendar" element={<Calendar />} /> */}
            <Route path="/auth/google/callback" element={<GoogleCalendarCallback />} />
            <Route path="/auth/x/callback" element={<XCallback />} />
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};

export default AppRoutes;
