import AppRoutes from './AppRoutes';
import { AuthProvider } from './contexts/authContext';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import { IntegrationsProvider } from './contexts/integrationsContext';
import './i18n';

const App = () => (
  <ThemeProvider theme={theme}>
    <AuthProvider>
      <IntegrationsProvider>
        <AppRoutes />
      </IntegrationsProvider>
    </AuthProvider>
  </ThemeProvider>
);

export default App;
