import { Navigate } from 'react-router-dom';

import { useAuth } from '../../../hooks/useAuth';
import { createUser } from '../../../api/users';
import ProfileForm from '../../../components/ProfileForm';
import { uploadProfilePhoto, getProfilePhotoUrl } from '../../../api/users';

const ProfileSetup = () => {
  const { currentUser, setCurrentUser, isSetupComplete, setIsSetupComplete } = useAuth();

  const handleSubmit = async (formData) => {
    const { name, description, phone, photo } = formData;
    let profilePhotoUrl = currentUser.profilePhotoUrl;

    if (photo) {
      await uploadProfilePhoto(photo);
      profilePhotoUrl = await getProfilePhotoUrl();

    }

    await createUser({
      firebaseId: currentUser.firebaseId,
      email: currentUser.email,
      name,
      description,
      phone,
    });

    setIsSetupComplete(true);
    setCurrentUser({ 
      ...currentUser, 
      name, 
      description,
      phone,
      profilePhotoUrl
    });
  };

  if (isSetupComplete) {
    if (!currentUser.acceptedTerms) {
      return <Navigate to="/legal" replace />;
    }
    return <Navigate to="/home" replace />;
  }

  return (
    <ProfileForm isEditing={false} onSubmit={handleSubmit} />
  );
};

export default ProfileSetup;
