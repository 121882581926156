import { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Paper, Button } from '@mui/material';
import { 
  AccountBalance as FinanceIcon,
  Download as DownloadIcon
} from '@mui/icons-material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import './swiper.css';
import { useTranslation } from 'react-i18next';
import { NetWorthChart } from './finance/NetWorthChart';
import { AssetClassChart } from './finance/AssetClassChart';
import { SectorChart } from './finance/SectorChart';

export const FinanceOverview = memo(({ 
  netWorthData, 
  targetGrowthPercentage,
  assetClassData,
  sectorData 
}) => {
  const { t } = useTranslation();
  const slides = [
    <NetWorthChart key="net-worth" netWorthData={netWorthData} targetGrowthPercentage={targetGrowthPercentage} />,
    <AssetClassChart key="asset-class" assetClassData={assetClassData} />,
    <SectorChart key="sector" sectorData={sectorData} />
  ];

  const handleDownloadReport = () => {
    const link = document.createElement('a');
    link.href = '/Portfolio_Overview_Sample.pdf';
    link.download = 'Portfolio Overview.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Paper sx={{ p: 3, height: 450, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <FinanceIcon />
          <Typography variant="h6">{t('financeOverview')}</Typography>
        </Box>
        <Button
          variant="outlined"
          startIcon={<DownloadIcon />}
          onClick={handleDownloadReport}
          size="small"
          sx={{ 
            backgroundColor: 'transparent',
            color: 'accent.main',
            '&:hover': {
              backgroundColor: 'transparent',
              color: 'accent.light',
            }
          }}
        >
          {t('downloadReport')}
        </Button>
      </Box>

      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        modules={[Navigation]}
        navigation
        pagination={{ clickable: true }}
        style={{ width: '100%', maxWidth: 600, margin: 'auto' }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            {slide}
          </SwiperSlide>
        ))}
      </Swiper>
    </Paper>
  );
});

FinanceOverview.propTypes = {
  netWorthData: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.instanceOf(Date).isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  targetGrowthPercentage: PropTypes.number.isRequired,
  assetClassData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  sectorData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

FinanceOverview.displayName = 'FinanceOverview'; 