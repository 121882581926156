import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getGoogleAuthUrl, handleGoogleCallback, checkGoogleCalendarIntegration, removeGoogleCalendarIntegration } from '../../api/integrations';
import { IntegrationStatus } from '../../constants/integrations';

export const useGoogleCalendarIntegration = (currentUser) => {
  const { t } = useTranslation();
  const [googleCalendarStatus, setGoogleCalendarStatus] = useState(IntegrationStatus.ACTIVE);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const checkIntegration = async () => {
    if (!currentUser) return;
    
    setLoading(true);
    try {
      const { data } = await checkGoogleCalendarIntegration();
      setGoogleCalendarStatus(data.status);
      setError(null);
    } catch (error) {
      console.error('Failed to check Google Calendar integration status:', error);
      setError(t('failedToCheckIntegration', { integration: 'Google Calendar' }));
      setGoogleCalendarStatus(IntegrationStatus.NEVER_INTEGRATED);
    } finally {
      setLoading(false);
    }
  };

  const initiateAuth = async () => {
    try {
      const { data } = await getGoogleAuthUrl();
      setError(null);
      window.location.href = data.url;
    } catch (error) {
      console.error('Google Calendar integration error:', error);
      setError(t('somethingWentWrong'));
    }
  };

  const handleCallback = async (code) => {
    setLoading(true);
    try {
      const { data } = await handleGoogleCallback(code);
      return data;
    } catch (error) {
      console.error('Failed to complete Google Calendar integration:', error);
      setError(t('failedToCompleteIntegration', { integration: 'Google Calendar' }));
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const removeIntegration = async () => {
    try {
      await removeGoogleCalendarIntegration();
      setGoogleCalendarStatus(IntegrationStatus.INACTIVE);
      setError(null);
    } catch (error) {
      console.error('Failed to remove Google Calendar integration:', error);
      setError(t('failedToRemoveIntegration', { integration: 'Google Calendar' }));
    }
  };

  return {
    status: googleCalendarStatus,
    error,
    loading,
    checkIntegration,
    initiateAuth,
    handleCallback,
    removeIntegration,
  };
}; 