import { memo } from 'react';
import { Box, Typography } from '@mui/material';
import { Instagram as InstagramIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export const InstagramProfile = memo(() => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1, mb: 4 }}>
        <InstagramIcon />
        <Typography variant="h6">{t('socialMediaActivity', { profile: "Instagram" })}</Typography>
      </Box>
      <Typography>Instagram integration coming soon...</Typography>
    </Box>
  );
});

InstagramProfile.displayName = 'InstagramProfile'; 