import { post } from './axios';

export const requestAssistance = async (travelRequestData) => {
  try {
    const response = await post(`travel/assistance`, travelRequestData);
    return response.data;
  } catch (error) {
    console.error('Error requesting assistance:', error);
    throw error;
  }
};