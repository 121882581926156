import { useNavigate } from 'react-router-dom';
import ProfileForm from '../../components/ProfileForm';
import { useAuth } from '../../hooks/useAuth';
import { updateUser } from '../../api/users';
import { uploadProfilePhoto, getProfilePhotoUrl } from '../../api/users';

const EditProfile = () => {
  const { currentUser, setCurrentUser } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (formData) => {
    if (!formData) {
      navigate('/home');
      return;
    }

    const { name, description, phone, photo } = formData;
    let updatedUser = { ...currentUser, name, description, phone };

    if (photo) {
      await uploadProfilePhoto(photo);
      const url = await getProfilePhotoUrl();
      updatedUser = { ...updatedUser, profilePhotoUrl: url };
    }

    await updateUser({ name, description, phone });
    setCurrentUser(updatedUser);
    navigate('/home');
    // window.location.reload();
  };

  return (
    <ProfileForm
      isEditing={true}
      onSubmit={handleSubmit}
      initialData={{
        name: currentUser.name,
        description: currentUser.description,
        phone: currentUser.phone,
      }}
    />
  );
};

export default EditProfile; 