import { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Paper } from '@mui/material';
import { 
  Twitter as TwitterIcon, 
  Instagram as InstagramIcon,
  Share as ShareIcon,
} from '@mui/icons-material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import './swiper.css';
import { useTranslation } from 'react-i18next';

import { useIntegrations } from '../../../hooks/useIntegrations';
import { XProfile } from './social/XProfile';
import { InstagramProfile } from './social/InstagramProfile';
import { IntegrationStatus } from '../../../constants/integrations';
import { NoSocialMediaIntegration } from './social/NoSocialMediaIntegration';

export const SocialMedia = memo(({ x }) => {
  const { t } = useTranslation();
  const { xStatus, instagramStatus } = useIntegrations();
  
  const getActivePlatforms = () => {
    const platforms = [];
    
    if (xStatus === IntegrationStatus.ACTIVE) {
        platforms.push({ 
          icon: <TwitterIcon />, 
          label: 'X', 
          component: <XProfile profile={x.profile} loading={x.loading} error={x.error} /> 
        });
    }
    
    if (instagramStatus === IntegrationStatus.ACTIVE) {
      platforms.push({
        icon: <InstagramIcon />,
        label: 'Instagram',
        component: <InstagramProfile />
      });
    }
    
    return platforms;
  };

  const platforms = getActivePlatforms();

  if (platforms.length === 0) {
    return <NoSocialMediaIntegration />
  }

  if (platforms.length === 1) {
    return (
      <Paper sx={{ p: 3, height: 450, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
          <ShareIcon />
          <Typography variant="h6">{t('socialMedia')}</Typography>
        </Box>
        {platforms[0].component}
      </Paper>
    );
  }

  return (
    <Paper sx={{ p: 3, height: 450, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
        <ShareIcon />
        <Typography variant="h6">{t('socialMedia')}</Typography>
      </Box>
      
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          modules={[Navigation]}
          navigation
          pagination={{ clickable: true }}
          style={{ width: '100%', maxWidth: 600, margin: 'auto' }}
        >
          {platforms.map((platform, index) => (
            <SwiperSlide key={index}>
              {platform.component}
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Paper>
  );
});

SocialMedia.propTypes = {
  x: PropTypes.shape({
    profile: PropTypes.shape({
      username: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      followersCount: PropTypes.number.isRequired,
      followingCount: PropTypes.number.isRequired,
      tweetCount: PropTypes.number.isRequired,
      recentTweets: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
        likeCount: PropTypes.number.isRequired,
        retweetCount: PropTypes.number.isRequired,
        replyCount: PropTypes.number.isRequired,
          })).isRequired,
    }),
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }),
};

SocialMedia.displayName = 'SocialMedia';
