import { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Paper, List, ListItem, ListItemText, CircularProgress } from '@mui/material';
import { format, isToday, isTomorrow } from 'date-fns';
import { 
    CalendarMonth as CalendarIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { useIntegrations } from '../../../hooks/useIntegrations';
import { IntegrationStatus } from '../../../constants/integrations';
import { NoEventsIntegrations } from './events/NoEventsIntegrations';

const formatEventDate = (dateStr) => {
  const date = new Date(dateStr);
  if (isToday(date)) return 'Today';
  if (isTomorrow(date)) return 'Tomorrow';
  return format(date, 'MMM d');
};

const formatEventTime = (dateStr) => {
  const date = new Date(dateStr);
  return format(date, 'h:mm a');
};

export const UpcomingEvents = memo(({ events, loading }) => {
  const { t } = useTranslation();
  const { googleCalendarStatus, outlookCalendarStatus } = useIntegrations();

  if (googleCalendarStatus !== IntegrationStatus.ACTIVE && outlookCalendarStatus !== IntegrationStatus.ACTIVE) {
    return <NoEventsIntegrations />
  }

  const upcomingEvents = events
    .filter(event => new Date(event.startTime) >= new Date())
    .slice(0, 10);

  return (
    <Paper sx={{ p: 3, height: 450, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 4 }}>
        <CalendarIcon sx={{ fontSize: 24, mr: 1 }} />
        <Typography variant="h6">{t('upcomingEvents')}</Typography>
        {loading && <CircularProgress size={24} />}
      </Box>
      {!loading && (
        <List>
          {upcomingEvents.map((event) => (
          <ListItem 
            key={event.id}
            sx={{ 
              px: 2, 
              py: 1,
              '&:not(:last-child)': {
                borderBottom: '1px solid',
                borderColor: 'divider'
              }
            }}
          >
            <ListItemText
              primary={event.title}
              secondary={
                <Box component="span" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>{formatEventDate(event.startTime)}</span>
                  <span>{formatEventTime(event.startTime)}</span>
                </Box>
              }
            />
          </ListItem>
        ))}
        {upcomingEvents.length === 0 && (
          <ListItem>
            <ListItemText primary="No upcoming events" />
          </ListItem>
        )}
        </List>
      )}
    </Paper>
  );
});

UpcomingEvents.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      startTime: PropTypes.string.isRequired,
      endTime: PropTypes.string.isRequired,
      attendees: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
};

UpcomingEvents.displayName = 'UpcomingEvents'; 