import { memo } from 'react';
import { Box, Typography, Paper, Button } from '@mui/material';
import { 
    CalendarMonth as CalendarIcon,
    Settings as SettingsIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const NoEventsIntegrations = memo(() => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
      <Paper sx={{ p: 3, height: 450, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 4 }}>
          <CalendarIcon sx={{ fontSize: 24, mr: 1 }} />
          <Typography variant="h6">{t('upcomingEvents')}</Typography>
        </Box>
        <Box sx={{ 
          flex: 1, 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          flexDirection: 'column',
          gap: 2,
          textAlign: 'center'
        }}>
          <Typography variant="h6">{t('noCalendarIntegration')}</Typography>
          <Typography variant="body2" color="text.secondary">
            {t('noCalendarIntegrationMessage')}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SettingsIcon />}
            onClick={() => navigate('/settings')}
            sx={{ mt: 2 }}
          >
            {t('goToSettings')}
          </Button>
        </Box>
      </Paper>
    );
});

NoEventsIntegrations.displayName = 'NoEventIntegrations';
