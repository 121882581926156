/** @jsxImportSource @emotion/react */
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

const SetupCompleteRoute = () => {
  const { currentUser, isSetupComplete, acceptedTerms } = useAuth();

  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }
  
  if(!isSetupComplete) {
    return <Navigate to="/profile-setup" replace />;
  }

  if (!acceptedTerms) {
    return <Navigate to="/legal" replace />;
  }

  return <Outlet />;
};

export default SetupCompleteRoute;
