import { useState } from 'react';
import PropTypes from 'prop-types';
import { 
  Modal, 
  Box, 
  Typography, 
  Button, 
  CircularProgress 
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { uploadDocumentPhoto } from '../../api/users';

const DocumentPhotoModal = ({ open, onClose, onUpload }) => {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [preview, setPreview] = useState(null);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      // Create preview URL
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;
    
    setUploading(true);
    try {
      const formData = new FormData();
      formData.append('documentPhoto', selectedFile);

      await uploadDocumentPhoto(selectedFile);

      await onUpload();
      handleClose();
    } catch (error) {
      console.error('Upload failed:', error);
    } finally {
      setUploading(false);
    }
  };

  const handleClose = () => {
    setSelectedFile(null);
    setPreview(null);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="upload-document-modal"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: 2,
        boxShadow: 24,
        p: 4,
      }}>
        <Typography variant="h6" component="h2" gutterBottom>
          {t('uploadDocument')}
        </Typography>
        
        {preview && (
          <Box 
            sx={{ 
              mb: 2, 
              width: '100%',
              height: 200,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
              borderRadius: 1,
              border: '1px solid #ddd'
            }}
          >
            <img 
              src={preview} 
              alt="Document preview" 
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain'
              }}
            />
          </Box>
        )}

        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="document-photo-upload"
          type="file"
          onChange={handleFileSelect}
        />
        <label htmlFor="document-photo-upload">
          <Button
            variant="outlined"
            component="span"
            fullWidth
            sx={{ mb: 2 }}
          >
            {t('chooseFile')}
          </Button>
        </label>
        
        {selectedFile && (
          <Typography variant="body2" sx={{ mb: 2 }}>
            {t('selectedFile', { file: selectedFile.name })}
          </Typography>
        )}

        <Button
          variant="contained"
          onClick={handleUpload}
          disabled={!selectedFile || uploading}
          fullWidth
        >
          {uploading ? <CircularProgress size={24} /> : t('upload')}
        </Button>
      </Box>
    </Modal>
  );
};

DocumentPhotoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired
};

export default DocumentPhotoModal; 