import { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { PieChart } from '@mui/x-charts';
import { useTranslation } from 'react-i18next';

import { generateColor } from './utils';

export const SectorChart = memo(({ sectorData }) => {
  const { t } = useTranslation();
  const dataWithColors = sectorData.map((item, index) => ({
    ...item,
    color: generateColor(index, sectorData.length)
  }));

  return (
    <Box sx={{ 
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      marginLeft: -12,
    }}>
      <PieChart
        series={[
          {
            arcLabel: (item) => `${item.value}%`,
            arcLabelMinAngle: 35,
            innerRadius: '75%',
            data: dataWithColors,
            highlightScope: { faded: 'global', highlighted: 'item' },
          },
        ]}
        height={300}
      />
      <Box sx={{ textAlign: 'center', mt: 2, marginBottom: 1 }}>
        <Typography variant="h6">
          {t('sectorDistribution')}
        </Typography>
      </Box>
    </Box>
  );
});

SectorChart.propTypes = {
  sectorData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

SectorChart.displayName = 'SectorChart'; 