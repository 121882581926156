import { get, post, put } from './axios';

export const getMyUser = async () => {
  try {
    const response = await get(`users/me`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user:', error);
    throw error;
  }
};

export const createUser = async (user) => {
  try {
    const response = await post('/users', user);
    return response.data;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};

export const updateUser = async (userData) => {
  try {
    const response = await put(`/users`, userData);
    return response.data;
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
};

export const acceptLegalTerms = async () => {
  try {
    const response = await post(`/users/accept-terms`);
    return response.data;
  } catch (error) {
    console.error('Error updating user legal acceptance:', error);
    throw error;
  }
};

export const uploadProfilePhoto = async (file) => {
  try {
    // Fetch the presigned URL from your API
    const { data } = await get(`/users/upload-profile-photo-presigned-url`);
    const { data: url } = data;
    // Upload the file to S3 using the presigned URL
    const response = await fetch(url, {
      method: 'PUT', // Use PUT if the presigned URL is for a PUT request
      body: file,
      headers: {
        'Content-Type': file.type, // Ensure the correct content type is set
      },
    });

    if (!response.ok) {
      throw new Error('Failed to upload photo to S3');
    }

    return response; // Return the URL for further use
  } catch (error) {
    console.error('Error uploading photo:', error);
    throw error;
  }
};

export const getProfilePhotoUrl = async () => {
  try {
    const { data } = await get(`/users/profile-photo-url`);
    const { data: url } = data;

    return url; // Return the URL for further use
  } catch (error) {
    console.error('Error fetching profile photo URL:', error);
    throw error;
  }
};

export const uploadDocumentPhoto = async (file) => {
  try {
    const { data } = await get(`/users/upload-document-presigned-url`);
    const { data: url } = data;

    const response = await fetch(url, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
      },
    });
    return response;
  } catch (error) {
    console.error('Error uploading document photo:', error);
    throw error;
  }
};

export const getDocumentPhotoUrl = async () => {
  try {
    const { data } = await get(`/users/document-url`);
    const { data: url } = data;
    return url;
  } catch (error) {
    console.error('Error fetching document URL:', error);
    throw error;
  }
};
