import PropTypes from 'prop-types';
import { Button, Menu, MenuItem, CircularProgress } from '@mui/material';
import { CheckCircle, Refresh, Add, KeyboardArrowDown } from '@mui/icons-material';
import { IntegrationStatus } from '../../constants/integrations';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';


export const IntegrationButton = ({ 
  status, 
  onClick, 
  onDisconnect,
  disabled = false 
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isActive = status === IntegrationStatus.ACTIVE;


  const statusConfig = {
    [IntegrationStatus.ACTIVE]: {
      color: 'green',
      label: t('connectedIntegration'),
      icon: <CheckCircle />
    },
    [IntegrationStatus.EXPIRED]: {
      color: '#FFA726',
      label: t('reconnectIntegration'),
      icon: <Refresh />
    },
    [IntegrationStatus.NEVER_INTEGRATED]: {
      color: '#FF4B26',
      label: t('connectIntegration'),
      icon: <Add />
    }
  };

  const config = statusConfig[status];


  const handleClick = (event) => {
    if (isActive) {
      setAnchorEl(event.currentTarget);
    } else {
      onClick();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDisconnect = async () => {
    setLoading(true);
    handleClose();
    await onDisconnect();
    setLoading(false);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        disabled={disabled}
        variant="contained"
        startIcon={disabled || loading ? null : config.icon}
        endIcon={isActive ? <KeyboardArrowDown /> : null}
        sx={{
          textTransform: 'none',
          borderRadius: 2,
          px: 3,
          py: 1,
          backgroundColor: config.color,
          '&:hover': {
            backgroundColor: config.color,
            opacity: 0.9
          }
        }}
      >
        {disabled ? t('upcomingIntegration') : loading ? <CircularProgress size={20} /> : config.label}
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem 
          onClick={handleDisconnect}
          sx={{ 
            color: 'error.main',
            '&:hover': {
              backgroundColor: 'error.lighter',
            }
          }}
        >
          {t('disconnectIntegration')}
        </MenuItem>
      </Menu>
    </>
  );
};

IntegrationButton.propTypes = {
  status: PropTypes.oneOf(Object.values(IntegrationStatus)).isRequired,
  onClick: PropTypes.func.isRequired,
  onDisconnect: PropTypes.func,
  disabled: PropTypes.bool,
}; 
