import PropTypes from 'prop-types';
import { Box, Typography, IconButton } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const SectionHeader = ({ title, isOpen, onToggle }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        bgcolor: 'primary.dark',
        p: 2,
        borderRadius: 1,
        cursor: 'pointer',
        border: '1px solid #e0e0e0',
        '&:hover': {
          opacity: 0.8,
        }
      }}
      onClick={onToggle}
    >
      <Typography variant="h6" color="secondary.main">{title}</Typography>
      <IconButton 
        size="small" 
        sx={{ color: 'secondary.main' }}
      >
        {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
    </Box>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default SectionHeader;