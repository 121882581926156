import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getXAuthUrl, handleXCallback, checkXIntegration, removeXIntegration } from '../../api/integrations';
import { IntegrationStatus } from '../../constants/integrations';

export const useXIntegration = (currentUser) => {
  const { t } = useTranslation();
  const [xStatus, setXStatus] = useState(IntegrationStatus.ACTIVE);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const checkIntegration = async () => {
    if (!currentUser) return;
    
    setLoading(true);
    try {
      const { data } = await checkXIntegration();
      setXStatus(data.status);
      setError(null);
    } catch (error) {
      console.error('Failed to check X integration status:', error);
      setError(t('failedToCheckIntegration', { integration: 'X' }));
      setXStatus(IntegrationStatus.NEVER_INTEGRATED);
    } finally {
      setLoading(false);
    }
  };

  const initiateAuth = async () => {
    try {
      const { data } = await getXAuthUrl();
      setError(null);
      window.location.href = data.url;
    } catch (error) {
      console.error('X integration error:', error);
      setError(t('somethingWentWrong'));
    }
  };

  const handleCallback = async (code, state) => {
    setLoading(true);
    try {
      const { data } = await handleXCallback(code, state);
      setError(null);
      return data;
    } catch (error) {
      console.error('Failed to complete X integration:', error);
      setError(t('failedToConnectIntegration', { integration: 'X' }));
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const removeIntegration = async () => {
    try {
      await removeXIntegration();
      setXStatus(IntegrationStatus.INACTIVE);
      setError(null);
    } catch (error) {
      console.error('Failed to remove X integration:', error);
      setError(t('failedToRemoveIntegration', { integration: 'X' }));
    }
  };

  return {
    status: xStatus,
    error,
    loading,
    checkIntegration,
    initiateAuth,
    handleCallback,
    removeIntegration,
  };
}; 