import { Box, Typography, Alert, CircularProgress, Button } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useIntegrations } from '../../hooks/useIntegrations';
import { IntegrationButton } from '../../components/IntegrationButton';
import AnimatedCard from '../../components/AnimatedCard';
import GoogleCalendarIcon from '../../assets/google_calendar_icon.png';
import OutlookIcon from '../../assets/outlook_icon.png';
import XIcon from '../../assets/x_icon.png';
import { IntegrationStatus } from '../../constants/integrations';
import { useAuth } from '../../hooks/useAuth';
import DocumentPhotoModal from './DocumentPhotoModal';
import { getDocumentPhotoUrl } from '../../api/users';

const Settings = () => {
  const { t } = useTranslation();
  const { currentUser, setCurrentUser } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const { 
    googleCalendarStatus, 
    googleCalendarError,
    xStatus,
    xError,
    loading,
    initiateGoogleCalendarAuth,
    removeGoogleCalendarIntegration,
    initiateXAuth,
    removeXIntegration,
  } = useIntegrations();

  const handleUploadSuccess = async () => {
    const url = await getDocumentPhotoUrl();
    setCurrentUser(prev => ({ ...prev, documentPhotoUrl: url }));
  };

  if (loading) {
    return (
      <Box padding={4} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box padding={4}>
      <Typography variant="h4" gutterBottom>
        {t('settings')}
      </Typography>
      
      {!currentUser?.documentPhotoUrl && (
        <Alert 
          severity="warning" 
          sx={{ mb: 3 }}
          action={
            <Button
              color="inherit"
              size="small"
              startIcon={<UploadIcon />}
              onClick={() => setOpenModal(true)}
            >
              {t('uploadDocument')}
            </Button>
          }
        >
          {t('missingDocumentAlert')}
        </Alert>
      )}
      
      <AnimatedCard animated={false}>
        <Typography variant="h6" gutterBottom>
          {t('integrations')}
        </Typography>
        
        <Box 
          sx={{ 
            mt: 2,
            mb: 5,
            position: 'relative',
            opacity: !currentUser?.documentPhotoUrl ? 0.6 : 1,
            pointerEvents: !currentUser?.documentPhotoUrl ? 'none' : 'auto'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <img 
              src={GoogleCalendarIcon} 
              alt="Google Calendar"
              style={{ width: '58px', height: '58px' }}
            />
            <Typography variant="subtitle1" gutterBottom>
              Google Calendar
            </Typography>
          </Box>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            {t('syncGoogleCalendarDescription')}
          </Typography>
          
          <Box sx={{ display: 'flex', gap: 2 }}>
            <IntegrationButton 
              status={googleCalendarStatus || IntegrationStatus.NEVER_INTEGRATED}
              onClick={initiateGoogleCalendarAuth}
              onDisconnect={removeGoogleCalendarIntegration}
              disabled={loading}
            />
          </Box>

          {googleCalendarError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {googleCalendarError}
            </Alert>
          )}
        </Box>
        <Box 
          sx={{ 
            mt: 2,
            mb: 5,
            position: 'relative',
            opacity: !currentUser?.documentPhotoUrl ? 0.6 : 1,
            pointerEvents: !currentUser?.documentPhotoUrl ? 'none' : 'auto'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <img 
              src={OutlookIcon} 
              alt="Outlook Calendar"
              style={{ width: '58px', height: '58px' }}
            />
            <Typography variant="subtitle1" gutterBottom>
              Outlook Calendar
            </Typography>
          </Box>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            {t('syncOutlookCalendarDescription')}
          </Typography>
          
          <IntegrationButton 
            status={IntegrationStatus.NEVER_INTEGRATED}
            onClick={() => {}}
            disabled={true}
          />

          {/* {outlookCalendarError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {outlookCalendarError}
            </Alert>
          )} */}
        </Box>
        <Box 
          sx={{
            mt: 2,
            mb: 5,
            position: 'relative',
            opacity: !currentUser?.documentPhotoUrl ? 0.6 : 1,
            pointerEvents: !currentUser?.documentPhotoUrl ? 'none' : 'auto'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <img 
              src={XIcon} 
              alt="X"
              style={{ width: '58px', height: '58px' }}
            />
            <Typography variant="subtitle1" gutterBottom>
              X (Twitter)
            </Typography>
          </Box>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            {t('syncXDescription')}
          </Typography>
          
          <Box sx={{ display: 'flex', gap: 2 }}>
            <IntegrationButton 
              status={xStatus || IntegrationStatus.NEVER_INTEGRATED}
              onClick={initiateXAuth}
              onDisconnect={removeXIntegration}
              disabled={loading}
            />
          </Box>

          {xError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {xError}
            </Alert>
          )}
        </Box>
      </AnimatedCard>

      <DocumentPhotoModal 
        open={openModal}
        onClose={() => setOpenModal(false)}
        onUpload={handleUploadSuccess}
      />
    </Box>
  );
};

export default Settings; 