/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { Box, Button, TextField, Typography, Alert, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { doPasswordReset } from '../../../firebase/auth';

const ResetPassword = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    try {
      await doPasswordReset(email);
      setMessage('Password reset email sent!');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoBack = () => {
    navigate('/login');
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
    >
      <Box
        width="28rem"
        padding={4}
        boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
        border="1px solid #e2e8f0"
        borderRadius="12px"
        textAlign="center"
        display="flex"
        flexDirection="column"
        gap={2}
      >
        <Typography variant="h5" fontWeight="600">
          {t('resetPassword')}
        </Typography>
    
        <form onSubmit={onSubmit} style={{ width: '100%' }}>
          <TextField
            type="email"
            label={t('email')}
            variant="outlined"
            fullWidth
            margin="normal"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            {t('sendResetEmail')}
          </Button>
        </form>

        {message && (
          <Alert severity="success" sx={{ width: '100%' }}>
            {message}
          </Alert>
        )}

        {error && (
          <Alert severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        )}

        <Link
          component="button"
          variant="body2"
          onClick={handleGoBack}
          sx={{ mt: 2, cursor: 'pointer' }}
        >
          {t('backToLogin')}
        </Link>
      </Box>
    </Box>
  );
};

export default ResetPassword;
