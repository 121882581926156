import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { auth } from '../../firebase/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { getMyUser, getProfilePhotoUrl, getDocumentPhotoUrl } from '../../api/users'; // Import the new API functions

export const AuthContext = React.createContext();

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSetupComplete, setIsSetupComplete] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, initializeUser);
    return unsubscribe;
  }, []);

  async function initializeUser(user) {
    setLoading(true);
    if (user) {
      try {
        const { data } = await getMyUser();

        let profilePhotoUrl = currentUser?.profilePhotoUrl;
        let documentPhotoUrl = currentUser?.documentPhotoUrl;

        if(!profilePhotoUrl) {
          profilePhotoUrl = await getProfilePhotoUrl();
        }
        if(!documentPhotoUrl) {
          documentPhotoUrl = await getDocumentPhotoUrl();
        }

        setCurrentUser({ ...user, firebaseId: user.uid, ...data, profilePhotoUrl, documentPhotoUrl });
        setIsSetupComplete(!!data?.email);
        setAcceptedTerms(!!data?.acceptedTerms);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setCurrentUser({ ...user, firebaseId: user.uid });
        setIsSetupComplete(false);
      }
    } else {
      setCurrentUser(null);
      setIsSetupComplete(false);
    }
    setLoading(false);
  }

  const value = {
    currentUser,
    isSetupComplete,
    acceptedTerms,
    setIsSetupComplete,
    setAcceptedTerms,
    setCurrentUser
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
